import { FlexCol, FlexRow, SectionContainer } from 'styles/styled'
import * as Styles from './style'
import RoadOne from 'public/assets/landing/road1.svg'
import RoadTwo from 'public/assets/landing/road2.svg'
import RoadThree from 'public/assets/landing/road3.svg'
import { FadeLeft, FadeRight } from 'shared/animations'
import SplitCard from 'shared/components/cards/SplitCard'

const roadMapData = [
  {
    img: RoadOne,
    info: [
      {
        year: '2021',
        description:
          'Begin Web 2 Missions developing the Quad Cores of VTR: 1) Recon, 2) Dealmaker, 3) Manager, and 4) Connect.',
      },
      {
        year: '2022',
        description:
          'Make some waves in the industry to spur more innovation and drive down competitor prices. Finish up MVP.',
      },
    ],
  },
  {
    img: RoadTwo,
    info: [
      {
        year: '2023',
        description:
          'Hire the executive operations team, begin Web 3 Missions developing first a membership utility token and its functions. Deploy MVP in tandem with Membership NFT. Work out operations and teamwork effectiveness. Develop win/win relationships with 3rd party providers in related industry specific products and services.',
      },
      {
        year: '2024',
        description:
          'Test and update the platform, per User requests. Improve Blockchain gamification systems with more immersive experiences and rewards. Attend and participate in industry events to promote and grow more memberships. Develop 3rd party Marketplaces.',
      },
    ],
  },
  {
    img: RoadThree,
    info: [
      {
        year: '2025',
        description:
          'Our valuation and capital raise year to allow investors the opportunity to grow with VTR success. Deploy Version 2.0 with enhanced ML and AI features from data collected to date. Deploy 3rd Party Marketplace to assist members more seamlessly.',
      },
      {
        year: '2026',
        description:
          'Maintain operation effectiveness, and enjoy continuously advancing the Retail Real Estate Industry into the future with the most advanced and affordable technologies.',
      },
    ],
  },
]

const RoadMapSection = () => {
  return (
    <SectionContainer id='Roadmap'>
      <Styles.RoadMapSectionContainer>
        <FlexCol gap='150px'>
          <Styles.HeadingContainer>
            <h2>RoadMap</h2>
            <p>
              Get a sneak peek into the exciting features and updates we have
              planned for the future.
            </p>
          </Styles.HeadingContainer>

          <Styles.RoadMapCardsWrapper>
            {roadMapData?.map((item, index) => (
              <div key={index}>
                <Styles.RoadMapCardsContainer
                  className={index === 1 ? 'reverse' : ''}
                >
                  {index === 1 ? (
                    <FadeRight>
                      <Styles.RoadMapImage src={item?.img} alt='brand-logo' />
                    </FadeRight>
                  ) : (
                    <FadeLeft>
                      <Styles.RoadMapImage src={item?.img} alt='brand-logo' />
                    </FadeLeft>
                  )}
                  {index === 1 ? (
                    <FadeLeft>
                      <SplitCard>
                        <Styles.RoadMapInfo>
                          {item?.info?.map((info, index) => (
                            <FlexRow
                              gap='12px'
                              key={index}
                              alignItems='flex-start'
                            >
                              <h3>{info?.year}</h3>
                              <p>{info?.description}</p>
                            </FlexRow>
                          ))}
                        </Styles.RoadMapInfo>
                      </SplitCard>
                    </FadeLeft>
                  ) : (
                    <FadeRight>
                      <SplitCard>
                        <Styles.RoadMapInfo>
                          {item?.info?.map((info, index) => (
                            <FlexRow
                              gap='12px'
                              key={index}
                              alignItems='flex-start'
                            >
                              <h3>{info?.year}</h3>
                              <p>{info?.description}</p>
                            </FlexRow>
                          ))}
                        </Styles.RoadMapInfo>
                      </SplitCard>
                    </FadeRight>
                  )}
                </Styles.RoadMapCardsContainer>
              </div>
            ))}
          </Styles.RoadMapCardsWrapper>
        </FlexCol>
      </Styles.RoadMapSectionContainer>
    </SectionContainer>
  )
}

export default RoadMapSection
